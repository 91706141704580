import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import HTMLReactParser from "html-react-parser";
import { 
    setActiveStep, 
    toggleDrawer, 
    getNewsAsync, 
    selectActiveStep, 
    selectDrawerOpen, 
    selectNewsItems
} from "./newsSlice";

import ArrowLeft from '@mui/icons-material/ArrowBackIos';
import ArrowRight from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import MobileStepper from '@mui/material/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { Box, IconButton, Typography } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function addLinkTarget(details) {
    const anchorRegex = />(?=[^>]+<\/a>)/i;
    const newDeets = details.replace(anchorRegex, ' target="_blank">');
   
    return newDeets;
}

export default function NewsTicker() {
    const dispatch = useDispatch();
    const activeStep = useSelector(selectActiveStep);
    const newsItems = useSelector(selectNewsItems);
    const drawerOpen = useSelector(selectDrawerOpen);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const margin = mobile ? "-5vw" : "0px";

    const togDrawer = () => {
        dispatch(toggleDrawer());
    }

    const handleNext = () => {
        const nextStep = activeStep === newsItems.length - 1 ? 0 : activeStep + 1;
        dispatch(setActiveStep(nextStep));
    };

    const handleBack = () => {
        const prevStep = activeStep === 0 ? newsItems.length - 1 : activeStep - 1;
        dispatch(setActiveStep(prevStep));
    };

    const handleStepChange = (step) => {
        dispatch(setActiveStep(step));
    };

    useEffect(() => {
        dispatch(getNewsAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (newsItems.length > 0 && !drawerOpen) {
            togDrawer();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newsItems])

    return (
        <>
            <Dialog anchor="bottom" open={drawerOpen} onClose={togDrawer}>
                <Grid container>
                    <Grid item xs></Grid>
                    {
                        newsItems.length < 1 ? (
                            <Grid item p={4} xs={10}>
                                <Typography variant="body1" color="white">
                                    No news is good news, right? Carry on.
                                </Typography>
                            </Grid>
                        ) : (
                            <Grid item xs={10}>
                                <SwipeableViews
                                    index={activeStep}
                                    onChangeIndex={handleStepChange}
                                    enableMouseEvents
                                >
                                    {
                                        newsItems.map((img, i) => (
                                            <div key={`newsItem${i}`}>
                                                <Grid container justifyContent="center" spacing={2} p={2}>
                                                    {img.image && (
                                                        <Grid item>
                                                            <img src={"https:"+img.image} height="150px" alt="album cover" />
                                                        </Grid>
                                                    )}
                                                    <Grid item justifyContent="center">
                                                        <Typography variant="h4" color="primary" textAlign="center">{img.title}</Typography>
                                                        <Box sx={{ typography: "body1", color: "white"}}>
                                                            {HTMLReactParser(addLinkTarget(img.details))}
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        ))
                                    }
                                </SwipeableViews>
                                {
                                    newsItems.length > 1 && (
                                        <MobileStepper
                                            className="stepper"
                                            variant="dots"
                                            steps={newsItems.length}
                                            position="static"
                                            activeStep={activeStep}
                                            sx={{ px: "2vw", pb: 2 }}
                                            nextButton={
                                                <Button size="small" onClick={handleNext}>
                                                <ArrowRight />
                                                </Button>
                                            }
                                            backButton={
                                                <Button size="small" onClick={handleBack}>
                                                <ArrowLeft />
                                                </Button>
                                            }
                                        />
                                    )
                                }
                            </Grid>
                        )
                    }
                    <Grid item xs sx={{ marginLeft: margin}}>
                        <IconButton size="large" onClick={togDrawer} sx={{ float: "right" }}>
                            <CloseIcon color="btnWhite" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}