import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    open: false
}

export const contactSlice = createSlice({
    name: "contact",
    initialState,
    reducers: {
        toggleContact: (state) => {
            state.open = !state.open;
        }
    }
})

export const {
    toggleContact
} = contactSlice.actions;

export const selectContactOpen = (state) => state.contact.open;

export default contactSlice.reducer;