import { useDispatch, useSelector } from "react-redux";
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { toggleContact, selectContactOpen } from "./contactSlice";

import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import EmailIcon from '@mui/icons-material/Email';
import Grid from '@mui/material/Grid';
import { IconButton, Typography } from "@mui/material";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PhoneIcon from '@mui/icons-material/Phone';

export default function ContactInfo() {
    const dispatch = useDispatch();
    const contactOpen = useSelector(selectContactOpen);
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const margin = mobile ? "-5vw" : "0px";

    const toggleContactDialog = () => {
        dispatch(toggleContact());
    }   

    return (
        <Dialog open={contactOpen} onClose={toggleContactDialog}>
                <Grid container>
                    <Grid item xs></Grid>
                        <Grid item p={4} xs={10}>
                            <Typography variant="h4" color="primary" textAlign="center">Contact</Typography>
                            <List>
                                <ListItem>
                                    <ListItemButton component="a" href="mailto:mickeystringermusic@gmail.com">
                                        <ListItemIcon>
                                            <EmailIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary="mickeystringermusic@gmail.com" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem>
                                    <ListItemButton component="a" href="tel:+12133946123">
                                        <ListItemIcon>
                                            <PhoneIcon color="primary" />
                                        </ListItemIcon>
                                        <ListItemText primary="(213) 394-6123" />
                                    </ListItemButton>
                                </ListItem>
                            </List>
                        </Grid>
                    <Grid item xs sx={{ marginLeft: margin}}>
                        <IconButton size="large" onClick={toggleContactDialog} sx={{ float: "right" }}>
                            <CloseIcon color="btnWhite" />
                        </IconButton>
                    </Grid>
                </Grid>
            </Dialog>
    )
}