import { createTheme } from '@mui/material/styles';

export const baseTheme = createTheme({
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundImage: "linear-gradient(#333, #000)",
                    border: "1px solid white"
                }
            }
        }
    },
    palette: {
        primary: {
            main: "#57ffca",
            contrastText: "#2e2e2e"
        },
        secondary: {
            main: "#ff0048",
            contrastText: "#fff"
        },
        btnWhite: {
            main: "#fff",
            contrastText: "#2e2e2e"
        }
    },
    typography: {
        h1: {
            fontFamily: "Bangers"
        },
        h4: {
            fontFamily: "Bangers"
        }
    }
})