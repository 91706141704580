import ListGroup from 'react-bootstrap/ListGroup';

function Nav() {
    return (
        <div className="navDiv">
            <ListGroup variant="flush">
                <ListGroup.Item action target="_blank" href="https://open.spotify.com/artist/23Lyz0YZs8hSei9tlBbZZY">Spotify</ListGroup.Item>
                <ListGroup.Item action target="_blank" href="https://music.apple.com/us/artist/mick-stringer/1492797234">Apple Music</ListGroup.Item>
                <ListGroup.Item action target="_blank" href="https://music.amazon.com/artists/B0881SLS6H/mickey-stringer?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_UAjOd2I3Wx3J49gaWiZ4C9DAi">Amazon Music</ListGroup.Item>
                <ListGroup.Item action target="_blank" href="https://www.instagram.com/mickeystringermusic/">Instagram</ListGroup.Item>
                <ListGroup.Item action target="_blank" href="https://www.youtube.com/channel/UCUxHvTMbv_vYKSe8UPiukHw">Youtube</ListGroup.Item>
                <ListGroup.Item action target="_blank" href="https://www.tiktok.com/t/ZTRNGE3jr/">TikTok</ListGroup.Item>
            </ListGroup>
        </div>
        
    );
}

export default Nav;