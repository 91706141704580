import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetchNews from './fetchNews';

const initialState = {
    activeStep: 0,
    newsItems: [],
    drawerOpen: false,
    newsStatus: "idle"
};

export const getNewsAsync = createAsyncThunk(
    "news/fetchNews",
    async () => {
        return await fetchNews();
    }
)

export const newsSlice = createSlice({
    name: "news",
    initialState,
    reducers: {
        setActiveStep: (state, action) => {
            state.activeStep = action.payload;
        },
        toggleDrawer: (state) => {
            state.drawerOpen = !state.drawerOpen;
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getNewsAsync.pending, (state) => {
            state.newsStatus = "loading";
        })
        .addCase(getNewsAsync.fulfilled, (state, action) => {
            state.newsStatus = "idle";
            state.newsItems = action.payload;
        })
    }
})

export const {
    setActiveStep,
    toggleDrawer
} = newsSlice.actions;

export const selectNewsItems = (state) => state.news.newsItems;
export const selectActiveStep = (state) => state.news.activeStep;
export const selectDrawerOpen = (state) => state.news.drawerOpen;

export default newsSlice.reducer;