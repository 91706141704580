import { toggleContact } from './contact/contactSlice';
import { toggleDrawer } from './NewsTicker/newsSlice';
import { useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import CampaignIcon from '@mui/icons-material/Campaign';
import EmailIcon from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import { Grid, Typography } from '@mui/material';

export default function Header () {
    const dispatch = useDispatch();

    const togDrawer = () => {
        dispatch(toggleDrawer());
    }

    const toggleContactDialog = () => {
        dispatch(toggleContact());
    }

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1} alignContent="center" alignItems="center" mt={2}>
                <Grid item xs></Grid>
                <Grid item xs="auto" className="navDiv">
                    <Typography variant="h1">{process.env.REACT_APP_TITLE}</Typography>
                </Grid>
                <Grid item xs>
                    <IconButton size="large" onClick={toggleContactDialog}>
                        <EmailIcon color="secondary"/>
                    </IconButton>
                    <IconButton size="large" onClick={togDrawer}>
                        <CampaignIcon color="secondary"/>
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    )
}