import bg from './Move_Still.png';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Contact from './Components/contact/Contact';
import Nav from './Components/Nav.js';
import NewsTicker from './Components/NewsTicker/NewsTicker';

import { baseTheme } from './app/themes';
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from '@mui/material/styles';
import Header from './Components/header';

function App() {
    return (
        <ThemeProvider theme={baseTheme} >
            <CssBaseline />
            <div className="App">
                <div className="backgroundDiv">
                    <img src={bg} alt="" className="image" />
                </div>
                <div className="App-header">
                    <Header />
                    <NewsTicker />
                    <Contact />
                    <Nav />
                </div>
            </div>
        </ThemeProvider>
    );
}

export default App;
