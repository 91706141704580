

export default async function fetchNews() {

    if (process.env.NODE_ENV === "sa") {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve(stubData);
            }, 300)
        })
    } else {
        return await fetch('/api/FetchNews').then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error("News retrieval failed")
        })
    }
}

const stubData = [
    {
        title: "The First Thing",
        details: "<p>This is info about the first thing.</p><p>And here's a <a href=\"https://google.com\">fake link</a> about it.</p>",
        image: "//i.guim.co.uk/img/media/26392d05302e02f7bf4eb143bb84c8097d09144b/446_167_3683_2210/master/3683.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=49ed3252c0b2ffb49cf8b508892e452d"
    },
    {
        title: "The Second Thing",
        details: "<p>This is info about the second thing.</p><p>And here's a <a href=\"https://google.com\">fake link</a> about it.</p>",
        image: "//www.dolphinproject.com/wp-content/uploads/2020/07/Dolphin-Facts-1.jpg"
    }
]